<template>
  <div :class="animation">
    <b-row>
      <b-col
        sm="12"
        md="8"
        lg="6"
      >
        <b-card title="Avis utilisateur">
          <b-form @submit.prevent="tryToSave">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Votre commentaire">
                  <b-form-input
                    v-model="$v.review.message.$model"
                    :class="{
                      'is-invalid': $v.review.message.$error,
                    }"
                  />
                  <div
                    v-if="$v.review.message.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.review.message.required">{{
                      $t("errors.required")
                    }}</span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-rating
                  v-model="$v.review.note.$model"
                  show-clear
                  show-value
                  no-border
                  variant="primary"
                  size="lg"
                >
                  <feather-icon
                    slot="icon-empty"
                    icon="FrownIcon"
                    size="18"
                  />
                  <feather-icon
                    slot="icon-half"
                    icon="MehIcon"
                    size="18"
                  />
                  <feather-icon
                    slot="icon-full"
                    icon="SmileIcon"
                    size="18"
                    class="text-success"
                  />
                  <feather-icon
                    slot="icon-clear"
                    icon="XCircleIcon"
                    size="18"
                  />
                </b-form-rating>
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <div class="text-center mb-2 mt-2">
                  <b-spinner
                    v-if="process"
                    variant="primary"
                  />
                </div>
                <div class="text-right">
                  <b-button
                    variant="outline-primary"
                    type="submit"
                  >
                    Enrégister
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      animation: null,
      review: {
        message: null,
        note: null,
        state: true,
      },
    }
  },
  validations: {
    review: {
      message: {
        required,
      },
      note: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'newReviewSuccess',
      error: 'newReviewError',
      process: 'newReviewProcess',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Avis utilisateur',
            text: 'Ajouté avec succès',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Avis utilisateur',
            text: "Une erreur est survenue lors de l'ajout",
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['newReview']),
    resetForm() {
      this.review = {
        message: null,
        note: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    tryToSave() {
      if (this.$store.getters.currentUser.verification_state !== 'VALID') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vérification de Compte',
            icon: 'AlertCircleIcon',
            text: "Veuillez procéder a la validation de votre compte pour procéder à l'échange",
            variant: 'warning',
          },
        })
        this.$router.push('/client-kyc-submit')
        return
      }
      this.animation = null
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        return
      }
      this.newReview({
        review: this.review.message,
        note: this.review.note,
        state: this.review.state,
      })
    },
  },
}
</script>

<style>

.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
